import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative flex justify-center">
                <div className="absolute w-full h-full top-0 left-0 z-20">
                    <video

                        playsInline
                        autoPlay
                        muted
                        loop
                        className="w-full h-full md:h-full object-cover"
                    >
                        <source
                            src={"https://firebasestorage.googleapis.com/v0/b/videos-de-stock.appspot.com/o/Ciudades%2FNashville2%2C%20TN.mp4?alt=media&token=7aba972c-25dd-49ca-858c-c6f4d9b8ff86"}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className="absolute w-full h-full top-0 left-0 z-20 bg-black/40"></div>
                <div className="z-20 relative w-4/5 mx-auto h-[220vh] md:h-[150vh]">
                    <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center">
                        <h1 className="text-white text-center">
                            {rpdata?.dbSlogan?.[0].slogan}
                        </h1>
                        <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
                        <div className="flex justify-center md:block">
                            <ButtonContent />
                        </div>
                        <div>
                            <img className="w-[300px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Finsignia.png?alt=media&token=8b6d813b-fb38-4b4d-864f-8f9375bb481d" alt="sello"/>
                        </div>
                    </div>
                    <div className="w-1/2"></div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;